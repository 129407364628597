export const initialValues: Record<string, string> = {
	json: `
{
	"page": 1,
	"some": [1, 2, 3, "Some content"]
}
	`,
	yaml: `
page: 1
some:
- 1
- 2
- 3
- Some content 
	`,
	text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sit amet lacus eget arcu ultricies vulputate. Morbi nec justo a urna varius tempus.',
	base64: 'TG9yZW0gaXBzdW0gZG9sb3Igc2l0IGFtZXQsIGNvbnNlY3RldHVyIGFkaXBpc2NpbmcgZWxpdC4g',
	texturi: 'http://localhost:3000/translate=こんにちは',
	uri: 'http://localhost:3000/translate=%E3%81%93%E3%82%93%E3%81%AB%E3%81%A1%E3%81%AF',
}
