import React, { useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	Container,
	Grid,
	Paper,
	TextInput,
} from '@mantine/core'
import { IconRotate } from '@tabler/icons'

export type PasswordOptions = {
	length: number
	useNumbers: boolean
	useSymbols: boolean
}

interface Props {
	onSubmit: (password: PasswordOptions) => void
}

const PasswordGeneratorForm = ({ onSubmit }: Props) => {
	const [options, setOptions] = useState<PasswordOptions>({
		length: 20,
		useNumbers: true,
		useSymbols: false,
	})

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, checked, type } = e.target
		setOptions(prevOptions => ({
			...prevOptions,
			[name]: type === 'checkbox' ? checked : value,
		}))
	}

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		onSubmit(options)
	}

	return (
		<Paper p="md" m="xl" shadow="xs">
			<form onSubmit={handleSubmit}>
				<Grid gutter="md">
					<Col span={12}>
						<TextInput
							type="number"
							name="length"
							label="Password Length:"
							min={8}
							max={64}
							value={options.length}
							onChange={handleChange}
						/>
					</Col>
					<Col span={12}>
						<Checkbox
							name="useNumbers"
							label="Use numbers (0-9)"
							checked={options.useNumbers}
							onChange={handleChange}
						/>
					</Col>
					<Col span={12}>
						<Checkbox
							name="useSymbols"
							label="Use symbols (!@#$%^&amp;*)"
							checked={options.useSymbols}
							onChange={handleChange}
						/>
					</Col>
					<Col span={12}>
						<Button
							fullWidth
							size={'xs'}
							type="submit"
							leftIcon={<IconRotate size={19} stroke={1.5} />}
						>
							Generate!
						</Button>
					</Col>
				</Grid>
			</form>
		</Paper>
	)
}

export default PasswordGeneratorForm
