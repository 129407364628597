import React, { useEffect, useState } from 'react'
import { Container, createStyles, Footer, Group, Text } from '@mantine/core'
import { useModal } from '../providers/ModalContext'
import { useNavigate } from 'react-router-dom'
import { CallGetTreeCount } from '../api/call-tree-count'
import { FeedbackModal } from './FeedbackModal'
import { EcologiModal } from './EcologiModal'

const useStyles = createStyles(theme => ({
	spread: {
		display: 'flex',
		justifyContent: 'space-between',
		userSelect: 'none',
		marginTop: '3px',
	},
	link: {
		cursor: 'pointer',
		fontWeight: 600,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.gray[5]
				: theme.colors.gray[7],
	},
	copyright: {
		color:
			theme.colorScheme === 'dark'
				? theme.colors.gray[5]
				: theme.colors.gray[7],
		fontSize: theme.fontSizes.xs,
		fontFamily: '"PT Mono", monospace',
		cursor: 'default',
	},
}))

export function SimpleFooter() {
	const { classes } = useStyles()
	const navigate = useNavigate()

	return (
		<Footer height={28}>
			<Container fluid className={classes.spread}>
				<Group spacing={'xl'}>
					<FeedbackModal />
					<EcologiModal />
				</Group>

				<Group spacing={'xl'}>
					{/* <Text
						className={classes.link}
						component="span"
						align="center"
						variant="text"
						size="sm"
						weight={600}
						style={{ fontFamily: 'Helvetica Neue, sans-serif' }}
						onClick={() => navigate('/terms')}
					>
						Terms
					</Text> */}
					<Text
						className={classes.link}
						variant="text"
						size="sm"
						weight={600}
						onClick={() => navigate('/cookies')}
					>
						Cookies
					</Text>
					<Text className={classes.copyright} variant="text">
						© 2023, freedev.tools
					</Text>
				</Group>
			</Container>
			<Group></Group>
		</Footer>
	)
}
