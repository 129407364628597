import {
	useMantineColorScheme,
	SegmentedControl,
	Group,
	Center,
	Box,
	MediaQuery,
	ActionIcon,
} from '@mantine/core'
import { IconSun, IconMoon } from '@tabler/icons'

export function SegmentedToggle() {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme()

	return (
		<Group position="center" my="xl">
			<MediaQuery largerThan="sm" styles={{ display: 'none' }}>
				<ActionIcon
					variant="default"
					value={colorScheme}
					onClick={() =>
						toggleColorScheme(
							colorScheme === 'dark' ? 'light' : 'dark'
						)
					}
				>
					{colorScheme === 'dark' ? (
						<Center>
							<IconSun size={16} stroke={1.5} />
						</Center>
					) : (
						<Center>
							<IconMoon size={16} stroke={1.5} />
						</Center>
					)}
				</ActionIcon>
			</MediaQuery>
			<MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
				<SegmentedControl
					value={colorScheme}
					onChange={(value: 'light' | 'dark') =>
						toggleColorScheme(value)
					}
					data={[
						{
							value: 'light',
							label: (
								<Center>
									<IconSun size={16} stroke={1.5} />
									<Box ml={10}>Light</Box>
								</Center>
							),
						},
						{
							value: 'dark',
							label: (
								<Center>
									<IconMoon size={16} stroke={1.5} />
									<Box ml={10}>Dark</Box>
								</Center>
							),
						},
					]}
				/>
			</MediaQuery>
		</Group>
	)
}
