import {
	createStyles,
	Header,
	Container,
	Group,
	Text,
	Avatar,
	Space,
	Burger,
	MediaQuery,
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { SegmentedToggle } from '../components/dark-mode-toggle'
import logo from '../static/logo.png'

const useStyles = createStyles(theme => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		width: '100%',
	},
	home: {
		cursor: 'pointer',
	},
	logo: {
		fontFamily: 'Futura',
	},
	highlight: {
		backgroundColor: theme.fn.variant({
			variant: 'filled',
		}).background,
		marginLeft: '-.25rem',
		padding: 5,
		paddingTop: 0,
		paddingBottom: 0,
		borderRadius: theme.radius.sm,
		display: 'inline-block',
		fontStyle: 'italic',
		color: theme.white,
	},
}))

interface Props {
	opened: boolean
	setOpened: () => void
}

export function SimpleHeader({ opened, setOpened }: Props) {
	const navigate = useNavigate()
	const { classes, cx } = useStyles()

	return (
		<Header height={60}>
			<Container fluid className={classes.header} mr={'sm'}>
				<MediaQuery largerThan="sm" styles={{ display: 'none' }}>
					<Burger opened={opened} onClick={setOpened} size="sm" />
				</MediaQuery>
				<Group
					spacing={5}
					className={classes.home}
					onClick={() => navigate('/')}
				>
					<Space w={'md'} />
					<Avatar src={logo} alt="logo" />
					<Space w={'xs'} />
					<Text
						component="span"
						align="center"
						variant="text"
						size="md"
						weight={700}
						style={{ fontFamily: 'Greycliff CF, sans-serif' }}
					>
						<span className={classes.highlight}>free</span>{' '}
						<span className={classes.logo}>DEV TOOLS</span>
					</Text>
				</Group>
				<SegmentedToggle />
			</Container>
		</Header>
	)
}
