import { createStyles, Container, Group, Text } from '@mantine/core'

const useStyles = createStyles(theme => ({
	header: {
		paddingLeft: '.25rem',
		paddingRight: '.25rem',
		display: 'flex',
		position: 'relative',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '2rem',
		marginBottom: '.25rem',
		marginTop: '-0.25rem',
	},
	title: {
		width: '25%',
	},
	highlight: {
		backgroundColor: theme.fn.variant({
			variant: 'light',
			color: theme.primaryColor,
		}).background,
		padding: 5,
		paddingTop: 2,
		borderRadius: theme.radius.sm,
		display: 'inline-block',
		color: theme.colorScheme === 'dark' ? theme.white : 'inherit',
	},
}))

interface Props {
	prefix?: string
	title?: string
	center: JSX.Element | JSX.Element[]
	right: JSX.Element | JSX.Element[]
}

export function BaseActionHeader({ prefix, title, center, right }: Props) {
	const { classes, cx } = useStyles()
	const renderTitle = (prefix?: string, format?: string) => {
		if (prefix === undefined && format === undefined) return <></>
		return (
			<Text
				component="span"
				align="center"
				variant="text"
				size="md"
				weight={700}
				style={{ fontFamily: 'Greycliff CF, sans-serif' }}
			>
				{prefix} <span className={classes.highlight}>{format}</span>
			</Text>
		)
	}

	return (
		<Container fluid className={classes.header}>
			<div className={classes.title}>
				{renderTitle(prefix ?? '', title)}
			</div>

			{center}
			{right}
		</Container>
	)
}
