import React, { useState } from 'react'
import { Container, createStyles, LoadingOverlay, Space } from '@mantine/core'
import { FileWithPath } from '@mantine/dropzone'
import { DropzoneButton } from './file-dropper'

const useStyles = createStyles(theme => ({
	wrapper: {
		width: '100%',
		borderLeft: `1px solid ${
			theme.colorScheme === 'dark'
				? theme.colors.dark[7]
				: theme.colors.gray[2]
		}`,
		borderRight: `1px solid ${
			theme.colorScheme === 'dark'
				? theme.colors.dark[7]
				: theme.colors.gray[2]
		}`,
	},
}))

interface Props {
	acceptFileFormats: string[]
	onDropFile: (files: FileWithPath[]) => Promise<void>
}

const FileInputPage = ({ acceptFileFormats, onDropFile }: Props) => {
	const { classes, cx } = useStyles()

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const triggerOnDropFile = async (files: FileWithPath[]) => {
		setIsLoading(true)
		try {
			await onDropFile(files)
		} catch (error) {
			console.error('error in file drop:', error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<Container size={'sm'} className={classes.wrapper}>
			<LoadingOverlay visible={isLoading} overlayBlur={2} />
			<Space h={'xl'} />
			<DropzoneButton
				onDrop={triggerOnDropFile}
				accept={acceptFileFormats}
			></DropzoneButton>
		</Container>
	)
}
export default FileInputPage
