import React, { useState } from 'react'
import {
	Button,
	Container,
	createStyles,
	Paper,
	Space,
	Text,
} from '@mantine/core'
import { CopyContent } from '../components/actions/copy-node'

const useStyles = createStyles(theme => ({
	emoji: {
		cursor: 'pointer',
	},
	description: {
		maxWidth: 540,
		margin: 'auto',
		fontStyle: 'italic',
	},
}))

const emojis = [
	'😀',
	'😃',
	'😄',
	'😁',
	'😆',
	'😅',
	'😂',
	'🤣',
	'😊',
	'😇',
	'🙂',
	'🙃',
	'😉',
	'😌',
	'😍',
	'😘',
	'😗',
	'😙',
	'😚',
	'😋',
	'😛',
	'😜',
	'🤪',
	'😝',
	'🤑',
	'🤗',
	'🤔',
	'🤐',
	'🤨',
	'😐',
	'😑',
	'😶',
	'😏',
	'😒',
	'🙄',
	'😬',
	'🤥',
	'😌',
	'😔',
	'😪',
	'🤤',
	'😴',
	'😷',
	'🤒',
	'🤕',
	'🤢',
	'🤮',
	'🤧',
	'😵',
	'🤯',
	'🤠',
	'😎',
	'🤓',
	'🧐',
	'😕',
	'😟',
	'🙁',
	'😮',
	'😯',
	'😲',
	'😳',
	'🥺',
	'😦',
	'😧',
	'😨',
	'😰',
	'😥',
	'😢',
	'😭',
	'😩',
	'😤',
	'😠',
	'😡',
	'🤬',
	'😈',
	'👿',
	'💀',
	'☠️',
	'💩',
	'🤡',
	'👹',
	'👺',
	'👻',
	'👽',
	'👾',
	'🤖',
	'😺',
	'😸',
	'😹',
	'😻',
	'😼',
	'😽',
	'🙀',
	'😿',
	'😾',
	'👐',
	'🙌',
	'👏',
	'🤝',
	'👍',
]

const getRandomEmoji = (): string => {
	const randomIndex = Math.floor(Math.random() * emojis.length)
	return emojis[randomIndex]
}

const RandomEmojiGenerator = () => {
	const { classes } = useStyles()
	const [emoji, setEmoji] = useState(getRandomEmoji)

	const handleGenerate = () => {
		setEmoji(getRandomEmoji)
	}

	return (
		<Container>
			<Paper p="md" m="xl" shadow="xs" style={{ textAlign: 'center' }}>
				<CopyContent content={emoji}>
					<Text
						align="center"
						style={{ fontSize: '3rem' }}
						className={classes.emoji}
					>
						{emoji}
					</Text>
				</CopyContent>

				<Text
					color="dimmed"
					size="sm"
					align="center"
					p="md"
					className={classes.description}
				>
					Click emoji to copy to clipboard
				</Text>
				<Button onClick={handleGenerate}>Generate Random Emoji</Button>
			</Paper>
			<Paper p="md" m="xl" shadow="xs" style={{ textAlign: 'center' }}>
				<Text color="dimmed" size="sm" align="center">
					Emojis, the hieroglyphics of the digital age, bring a
					vibrant twist to modern communication, often transforming
					dull text into a hilarious masterpiece. 🦄 With just a tap
					on a random emoji generator, you can unleash an avalanche of
					laughter, turning an ordinary message into a comedic
					adventure – because who doesn't love a surprise cactus
					party? 🌵🎉
				</Text>
			</Paper>
		</Container>
	)
}

export default RandomEmojiGenerator
