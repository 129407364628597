import React, { useEffect, useState } from 'react'
import { Anchor, Container, createStyles, Modal, Text } from '@mantine/core'
import { CallGetTreeCount } from '../api/call-tree-count'

const ECOLOGI_PAGE = 'https://ecologi.com'

const useStyles = createStyles(theme => ({
	text: {
		cursor: 'default',
		fontSize: theme.fontSizes.sm,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.gray[5]
				: theme.colors.gray[7],
	},
	treeCount: {
		cursor: 'default',
		fontSize: theme.fontSizes.sm,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.yellow[5]
				: theme.colors.yellow[7],
	},
	prompt: {
		fontSize: theme.fontSizes.sm,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.gray[6]
				: theme.colors.gray[7],
	},
	link: {
		cursor: 'pointer',
		fontWeight: 600,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.gray[5]
				: theme.colors.gray[7],
	},
}))

export const EcologiModal = () => {
	const { classes } = useStyles()
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const [treeCount, setTreeCount] = useState<number>(0)

	useEffect(() => {
		CallGetTreeCount().then(result => {
			setTreeCount(result)
		})
	}, [])

	return (
		<>
			{!isOpen ? (
				<Text className={classes.text} variant="text" size="sm">
					<span className={classes.prompt}>
						<span className={classes.treeCount}>{treeCount}</span>{' '}
						trees were planted by this site.{' '}
						<span
							className={classes.link}
							onClick={() => setIsOpen(true)}
						>
							How?
						</span>
					</span>
				</Text>
			) : (
				<Modal
					overlayProps={{ opacity: 0.55, blur: 3 }}
					opened={isOpen}
					title="Planting trees and writing software"
					onClose={() => {
						setIsOpen(false)
					}}
					centered
				>
					<Container fluid>
						<Text size={'sm'} color={'dimmed'}>
							This website supports tree planting operations at{' '}
							<Anchor href={ECOLOGI_PAGE} target="_blank">
								Ecologi
							</Anchor>{' '}
							by monthly donations. To read more about how you can
							contribute to the reduction of greenhouse gases by
							tree planting, go to{' '}
							<Anchor href={ECOLOGI_PAGE} target="_blank">
								{ECOLOGI_PAGE}
							</Anchor>
							.
						</Text>
					</Container>
				</Modal>
			)}
		</>
	)
}
