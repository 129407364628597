import { useRef, useState } from 'react'
import { Text, Group, Button, createStyles } from '@mantine/core'
import { Dropzone, FileWithPath } from '@mantine/dropzone'
import { IconCloudUpload, IconX, IconDownload } from '@tabler/icons'

const useStyles = createStyles(theme => ({
	wrapper: {
		position: 'relative',
		marginBottom: 30,
	},

	dropzone: {
		borderWidth: 1,
		paddingBottom: 50,
	},

	icon: {
		color:
			theme.colorScheme === 'dark'
				? theme.colors.dark[3]
				: theme.colors.gray[4],
	},

	control: {
		position: 'absolute',
		width: 250,
		left: 'calc(50% - 125px)',
		bottom: -20,
	},
}))

export interface DropZoneProps {
	onDrop: (files: FileWithPath[]) => Promise<void>
	accept: string[]
}

export function DropzoneButton(props: DropZoneProps) {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const { classes, theme } = useStyles()

	const openRef = useRef<() => void>(null)

	return (
		<div className={classes.wrapper}>
			<Dropzone
				openRef={openRef}
				multiple={false}
				onDrop={(files: FileWithPath[]) => {
					setIsLoading(true)
					props.onDrop(files).finally(() => {
						setIsLoading(false)
					})
				}}
				onReject={files => console.error('rejected files', files)}
				className={classes.dropzone}
				radius="md"
				maxSize={30 * 1024 ** 2}
				loading={isLoading}
			>
				<div style={{ pointerEvents: 'none' }}>
					<Group position="center">
						<Dropzone.Accept>
							<IconDownload
								size={50}
								color={theme.colors[theme.primaryColor][6]}
								stroke={1.5}
							/>
						</Dropzone.Accept>
						<Dropzone.Reject>
							<IconX
								size={50}
								color={theme.colors.red[6]}
								stroke={1.5}
							/>
						</Dropzone.Reject>
						<Dropzone.Idle>
							<IconCloudUpload
								size={50}
								color={
									theme.colorScheme === 'dark'
										? theme.colors.dark[0]
										: theme.black
								}
								stroke={1.5}
							/>
						</Dropzone.Idle>
					</Group>

					<Text align="center" weight={700} size="lg" mt="xl">
						<Dropzone.Accept>Drop files here</Dropzone.Accept>
						<Dropzone.Reject>Max file size: 10MB</Dropzone.Reject>
						<Dropzone.Idle>Upload file</Dropzone.Idle>
					</Text>
					<Text align="center" size="sm" mt="xs" color="dimmed">
						Drag&apos;n&apos;drop files here to upload. We can
						accept only files that are less than 10mb in size.
					</Text>
				</div>
			</Dropzone>

			<Button
				className={classes.control}
				size="md"
				radius="xl"
				onClick={() => openRef.current?.()}
			>
				Select files
			</Button>
		</div>
	)
}
