import { Container, createStyles, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import { FileWithPath, MIME_TYPES } from '@mantine/dropzone'
import { Helmet } from 'react-helmet'
import ConfigurationPane from '../components/ConfigurationPane'
import TextInputPane from '../components/TextInputPane'
import FileInputPane from '../components/FileInputPane'
import { FindTransformerByFormats } from '../transform/find'
import { useLocation, useNavigate } from 'react-router-dom'
import { isBijective } from '../transform/options'
import { InputType, SourcePaneActions } from '../components/InputActions'
import { DestinationPaneActions } from '../components/OutputActions'
import { useDebouncedValue, useHotkeys } from '@mantine/hooks'
import { CallGoModel } from '../api/call-go-model'
import { CallTSModel } from '../api/call-ts-model'

const useStyles = createStyles(theme => ({
	root: {
		paddingLeft: 0,
	},
	inner: {
		padding: 0,
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '100%',
	},
	content: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		position: 'relative',
	},
	column: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
}))

interface Props {
	conversionSource: string
	conversionDestination: string
	defaultSourceContent: string | undefined
}

export function InputOutputPage({
	conversionSource,
	conversionDestination,
	defaultSourceContent,
}: Props) {
	const { classes, cx } = useStyles()
	const navigate = useNavigate()
	const location = useLocation()
	const [inputType, setInputType] = useState<InputType>(() => {
		return conversionSource !== 'image' ? InputType.Text : InputType.File
	})
	const canSetInputType = conversionSource !== 'image'
	const [sourceContent, setSourceContent] = useState<string | undefined>(
		defaultSourceContent
	)
	const [debouncedSourceContent] = useDebouncedValue(sourceContent, 200)
	const [destinationContent, setDestinationContent] = useState<string>('')
	const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false)

	const onEditSourceContent = (code: string) => {
		setSourceContent(code)
	}

	const onClearSourceContent = () => {
		// TODO: add config and make this useful
		setSourceContent('')
	}

	const canSwap = isBijective(conversionSource, conversionDestination)

	const onSwap = () => {
		// navigate to same page, but swapping output with input
		navigate(location.pathname, {
			state: {
				src: conversionDestination,
				dst: conversionSource,
				srcData: destinationContent,
			},
		})
	}

	const toggleInputType = () => {
		setInputType(
			inputType === InputType.Text ? InputType.File : InputType.Text
		)
	}

	useHotkeys([['mod+K', () => toggleInputType()]])

	const onDropFile = async (files: FileWithPath[]): Promise<void> => {
		if (files.length === 0) return Promise.reject()

		const file = files[0]
		switch (file.type) {
			case 'image/png':
			case 'image/jpeg':
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () =>
					setSourceContent(reader.result!.toString())
				reader.onerror = error => Promise.reject(error)
				break
			default:
				try {
					const content = await file.text()
					setSourceContent(content)
				} catch (error) {
					Promise.reject(`unable to load file: ${error}`)
				}
				break
		}
		return Promise.resolve()
	}

	const getCodeGenerator = () => {
		switch (conversionDestination) {
			case 'go':
				return CallGoModel
			case 'typescript':
				return CallTSModel
			default:
				throw new Error(
					'no code generator found for ' + conversionDestination
				)
		}
	}

	const setDestination = (content: string | undefined) => {
		try {
			if (content === undefined || content === '') return

			const transformer = FindTransformerByFormats(
				conversionSource,
				conversionDestination
			)
			const transformedValue = transformer(content) ?? ''
			setDestinationContent(transformedValue)
		} catch (error) {
			console.error(error)
		}
	}

	const setDestinationAsync = async (content: string | undefined) => {
		if (content === undefined || content === '') return
		setIsLoadingResults(true)

		try {
			const generator = getCodeGenerator()
			const contentJSON = JSON.parse(content)
			const models = await generator(contentJSON)
			setDestinationContent(models)
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoadingResults(false)
		}
	}

	useEffect(() => {
		// what triggers the conversion
		if (['go', 'typescript'].includes(conversionDestination)) {
			// if destination requires async code, abort
			// TODO: Make util and enum
			return
		}
		setDestination(sourceContent)
	}, [sourceContent, conversionDestination])

	useEffect(() => {
		// async transformers
		if (!['go', 'typescript'].includes(conversionDestination)) {
			return
		}
		setDestinationAsync(debouncedSourceContent)
	}, [debouncedSourceContent])

	useEffect(() => {
		// setting default value when switching page/format
		setSourceContent(defaultSourceContent)
	}, [defaultSourceContent])

	useEffect(() => {
		if (conversionSource === 'image') {
			setInputType(InputType.File)
		} else {
			setInputType(InputType.Text)
		}
	}, [conversionSource])
	return (
		<Container
			fluid
			className={classes.root}
			onScroll={e => {
				e.preventDefault()
			}}
		>
			<Helmet>
				<title>Convert to {conversionDestination.toUpperCase()}</title>
				<meta
					name="description"
					content="Transform data between common formats such as YAML, JSON, and TOML. Also offers multiple hashing and encoding utils!"
				/>
			</Helmet>
			<Container fluid className={classes.inner}>
				<div className={classes.content}>
					<div className={classes.column}>
						<SourcePaneActions
							inputType={inputType}
							onSetInputType={
								canSetInputType ? toggleInputType : undefined
							}
							format={conversionSource}
							content={sourceContent}
							onClear={onClearSourceContent}
						/>
						{inputType === InputType.Text ? (
							<TextInputPane
								value={sourceContent}
								language={conversionSource}
								onEditContent={onEditSourceContent}
							/>
						) : (
							<FileInputPane
								acceptFileFormats={Object.values(
									MIME_TYPES
								).map(mt => mt)}
								onDropFile={onDropFile}
							/>
						)}
					</div>

					<ConfigurationPane
						canSwapContent={canSwap}
						onSwapContent={onSwap}
					/>
					<div className={classes.column}>
						<DestinationPaneActions
							content={destinationContent}
							format={conversionDestination}
						/>
						<TextInputPane
							value={destinationContent}
							language={conversionDestination}
							onEditContent={undefined}
						/>
					</div>
				</div>
			</Container>
		</Container>
	)
}
