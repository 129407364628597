import { MD5, SHA256, SHA512, SHA1 } from 'crypto-js'

export const toMD5 = (s: string): string => {
	return MD5(s).toString()
}

export const toSHA1 = (s: string): string => {
	return SHA1(s).toString()
}

export const toSHA256 = (s: string): string => {
	return SHA256(s).toString()
}

export const toSHA512 = (s: string): string => {
	return SHA512(s).toString()
}
