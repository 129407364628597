import {
	Text,
	Container,
	ThemeIcon,
	Title,
	SimpleGrid,
	createStyles,
} from '@mantine/core'
import { TablerIcon } from '@tabler/icons'

const useStyles = createStyles(theme => ({
	wrapper: {
		paddingTop: 80,
		paddingBottom: 50,
	},

	item: {
		display: 'flex',
	},

	itemIcon: {
		padding: theme.spacing.xs,
		marginTop: `calc(${theme.spacing.xs} / 2)`,
		marginRight: theme.spacing.md,
	},

	itemTitle: {
		marginBottom: `calc(${theme.spacing.xs} / 2)`,
	},

	supTitle: {
		textAlign: 'center',
		textTransform: 'uppercase',
		fontWeight: 800,
		fontSize: theme.fontSizes.sm,
		color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
			.color,
		letterSpacing: 0.5,
	},

	title: {
		lineHeight: 1,
		textAlign: 'center',
		marginTop: theme.spacing.xl,
	},

	description: {
		textAlign: 'center',
		marginTop: theme.spacing.xs,
	},

	highlight: {
		backgroundColor: theme.fn.variant({
			variant: 'light',
		}).background,
		padding: 5,
		borderRadius: theme.radius.sm,
		display: 'inline-block',
		color: theme.colorScheme === 'dark' ? theme.white : 'inherit',
	},
}))

interface FeatureIcon {
	title: React.ReactNode
	description: React.ReactNode
	icon: TablerIcon
}

interface FeaturesImagesProps {
	supTitle: React.ReactNode
	description: React.ReactNode
	data: FeatureIcon[]
}

export function FeaturesImages({
	supTitle,
	description,
	data,
}: FeaturesImagesProps) {
	const { classes } = useStyles()

	const items = data.map((item, index) => (
		<div className={classes.item} key={index}>
			<ThemeIcon
				className={classes.itemIcon}
				size={44}
				radius="sm"
				variant="gradient"
				gradient={{ deg: 133, from: 'blue', to: 'cyan' }}
			>
				<item.icon size={26} stroke={1.5} />
			</ThemeIcon>

			<div>
				<Text weight={700} size="lg" className={classes.itemTitle}>
					{item.title}
				</Text>
				<Text color="dimmed">{item.description}</Text>
			</div>
		</div>
	))

	return (
		<Container size={700} className={classes.wrapper}>
			<Text className={classes.supTitle}>{supTitle}</Text>

			<Title className={classes.title} order={2}>
				Just a <span className={classes.highlight}>BIG</span> bunch of
				tools
			</Title>

			<Container size={660} p={0}>
				<Text color="dimmed" className={classes.description}>
					{description}
				</Text>
			</Container>

			<SimpleGrid
				cols={2}
				spacing={50}
				breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
				style={{ marginTop: 30 }}
			>
				{items}
			</SimpleGrid>
		</Container>
	)
}
