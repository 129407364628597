import {
	createStyles,
	Container,
	Title,
	Text,
	Button,
	Group,
	Space,
	MediaQuery,
	Paper,
} from '@mantine/core'
import { LargeCopy } from '../components/actions/large-copy'
import randomWord from 'random-words'
import { IconRotate } from '@tabler/icons'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { CopyContent } from '../components/actions/copy-node'

const useStyles = createStyles(theme => ({
	root: {
		paddingTop: 80,
		paddingBottom: 80,
	},

	inner: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	image: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		zIndex: 0,
		opacity: 0.75,
	},

	content: {
		paddingTop: 220,
		position: 'relative',
		zIndex: 1,

		[theme.fn.smallerThan('sm')]: {
			paddingTop: 120,
		},
	},

	title: {
		fontFamily: `Helvetica Neue, ${theme.fontFamily}`,
		textAlign: 'center',
		fontWeight: 900,
		fontSize: 42,
		cursor: 'pointer',

		[theme.fn.smallerThan('md')]: {
			fontSize: 32,
		},
		[theme.fn.smallerThan('sm')]: {
			fontSize: 26,
		},

		marginBottom: theme.spacing.xl,
	},

	description: {
		maxWidth: 540,
		margin: 'auto',
		fontStyle: 'italic',
		marginBottom: theme.spacing.xl,
	},
	xkcd: {
		fontSize: '9pt',
	},
}))

export function MemorablePasswordGenerator() {
	const { classes } = useStyles()

	const generatePassword = () => {
		return randomWord({
			wordsPerString: 1,
			exactly: 4,
			maxLength: 1,
			join: '',
			formatter(word, index) {
				return word[0].toUpperCase() + word.substring(1)
			},
		}).toString()
	}

	const [password, setPassword] = useState<string>(generatePassword())

	return (
		<Container fluid className={classes.root}>
			<Helmet>
				<title>Memorable Password Generator </title>
				<meta
					name="description"
					content="Generate memorable passwords according to the correct horse battery staple principle."
				/>
				<script type="application/ld+json">
					{`
                        {
                            "@context": "http://schema.org",
                            "@type": "WebPage",
                            "name": "Memorable Password Generator",
                            "description": "Generate memorable passwords according to the correct horse battery staple principle"
                        }
                    `}
				</script>
			</Helmet>

			<div className={classes.inner}>
				<div className={classes.content}>
					<CopyContent content={password}>
						<Title size={'xs'} className={classes.title}>
							{password}
						</Title>
					</CopyContent>

					<Text
						color="dimmed"
						size="sm"
						align="center"
						className={classes.description}
					>
						Click password to copy to clipboard
					</Text>

					<Group position="center">
						<Button
							size={'xs'}
							leftIcon={<IconRotate size={19} stroke={1.5} />}
							onClick={() => setPassword(generatePassword())}
						>
							Another!
						</Button>
						<LargeCopy content={password} />
					</Group>
				</div>
				<Space h={'xl'} />

				<Paper
					p="md"
					m="xl"
					shadow="xs"
					style={{ textAlign: 'center' }}
				>
					<Text color="dimmed" size="sm" align="center">
						The XKCD "correct horse battery staple" principle
						suggests creating strong passwords by combining four
						random, common words, making them easier for humans to
						remember yet difficult for computers to crack. These
						passphrase-style passwords are considered stronger than
						random character passwords because they have increased
						length and entropy, which significantly raises the
						number of possible combinations a brute-force attack
						must attempt.
					</Text>
					<MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
						<a
							href="https://xkcd.com/936/"
							target={'_blank'}
							rel="noreferrer"
						>
							<Text className={classes.xkcd} variant={'link'}>
								https://xkcd.com/936/
							</Text>
						</a>
					</MediaQuery>
				</Paper>
			</div>
		</Container>
	)
}
