import { useLocation, useSearchParams } from 'react-router-dom'
import { Container, createStyles } from '@mantine/core'
import { initialValues } from './constants'
import { InputOutputPage } from './InputOutputPage'
import { useScrollLock } from '@mantine/hooks'

const useStyles = createStyles(theme => ({
	root: {
		padding: 0,
	},

	inner: {
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '100%',
	},

	content: {
		display: 'flex',
		flexDirection: 'row',
		position: 'relative',
		width: '100%',
	},
}))

interface FormatRouteParams {
	src: string
	srcData?: string
	dst: string
}

export function ConversionPage() {
	const { classes, cx } = useStyles()
	const [scrollLocked, setScrollLocked] = useScrollLock()

	const [searchParams] = useSearchParams()
	const location = useLocation()
	const state = location.state as FormatRouteParams

	const conversionSource = state ? state.src : searchParams.get('src') ?? ''
	const conversionDestination = state
		? state.dst
		: searchParams.get('dst') ?? ''

	const initialValue =
		state && state.srcData
			? state.srcData
			: (
					initialValues[
						`${conversionSource}${conversionDestination}`
					] ||
					initialValues[conversionSource] ||
					''
			  ).trim()

	return (
		<Container
			fluid
			className={classes.root}
			onScroll={e => {
				e.preventDefault()
			}}
		>
			<InputOutputPage
				conversionSource={conversionSource}
				conversionDestination={conversionDestination}
				defaultSourceContent={initialValue}
			></InputOutputPage>
		</Container>
	)
}
