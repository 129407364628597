import React from 'react'
import {
	Container,
	Paper,
	Text,
	Col,
	Grid,
	Divider,
	Anchor,
} from '@mantine/core'

export const CookiesPolicyPage = () => {
	return (
		<Container>
			<Paper p="md" shadow="xs" style={{ marginTop: 30 }}>
				<Grid>
					<Col span={12}>
						<Text align="center" size="xl" weight={500}>
							Cookies Policy
						</Text>
					</Col>
				</Grid>
				<Divider style={{ marginTop: 10, marginBottom: 10 }} />
				<Text>
					We value your privacy and ensure that our website does not
					store any personal information about you. However, we do use
					Google Analytics to track and improve the features of our
					website.
				</Text>
				<Text>
					Google Analytics is a web analytics service provided by
					Google Inc. ("Google"). Google Analytics uses "cookies,"
					which are text files placed on your computer, to help the
					website analyze how users use the site. The information
					generated by the cookie about your use of the website
					(including your IP address) will be transmitted to and
					stored by Google on servers in the United States.
				</Text>
				<Text>
					Google will use this information for the purpose of
					evaluating your use of the website, compiling reports on
					website activity for website operators, and providing other
					services relating to website activity and internet usage.
					Google may also transfer this information to third parties
					where required to do so by law, or where such third parties
					process the information on Google's behalf. Google will not
					associate your IP address with any other data held by
					Google.
				</Text>
				<Text>
					By using this website, you consent to the processing of data
					about you by Google in the manner and for the purposes set
					out above. To learn more about how Google Analytics handles
					your data, you can visit their privacy policy at{' '}
					<Anchor
						href="https://policies.google.com/privacy"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://policies.google.com/privacy
					</Anchor>
					.
				</Text>
			</Paper>
		</Container>
	)
}
