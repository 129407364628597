import { Button, Tooltip } from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { IconSwitchHorizontal, IconCheck } from '@tabler/icons'

interface Props {
	onSwap?: () => void
}

export function Swap({ onSwap }: Props) {
	const clipboard = useClipboard()
	return (
		<Tooltip
			label="Swapped!"
			offset={5}
			position="bottom"
			radius="xl"
			// transition="slide-down"
			// transitionDuration={100}
			opened={clipboard.copied}
		>
			<Button
				variant="light"
				leftIcon={
					clipboard.copied ? (
						<IconCheck size={14} stroke={1.5} />
					) : (
						<IconSwitchHorizontal size={14} stroke={1.5} />
					)
				}
				radius="md"
				size="xs"
				styles={{
					root: {
						paddingRight: 0,
						height: 26,
						marginLeft: '0.5rem',
						marginRight: '0.5rem',
					},
				}}
				disabled={onSwap === undefined}
				onClick={() => {
					if (onSwap) {
						onSwap()
					}
				}}
			></Button>
		</Tooltip>
	)
}
