import { fromBase64, fromUri, toBase64, toUri } from './encode-decode'
import { toMD5, toSHA1, toSHA256, toSHA512 } from './hashing'
import { JSONToYaml, YamlToJSON } from './yaml-json'

export const FindTransformerByFormats = (src: string, dst: string) => {
	const sign = `${src}-${dst}`
	switch (sign) {
		case 'yaml-json':
			return YamlToJSON
		case 'json-yaml':
			return JSONToYaml
		case 'text-md5':
			return toMD5
		case 'text-sha1':
			return toSHA1
		case 'text-sha256':
			return toSHA256
		case 'text-sha512':
			return toSHA512
		case 'text-base64':
			return toBase64
		case 'base64-text':
			return fromBase64
		case 'image-base64':
			return toBase64
		case 'base64-image':
			return fromBase64
		case 'text-uri':
			return toUri
		case 'uri-text':
			return fromUri
		default:
			throw new Error(`No transformer for format pair ${sign}`)
	}
}
