import { enc } from 'crypto-js'

export const toBase64 = (s: string): string => {
	return enc.Base64.stringify(enc.Utf8.parse(s))
}

export const fromBase64 = (s: string): string => {
	return enc.Base64.parse(s).toString(enc.Utf8)
}

export const toUri = (s: string): string => {
	return encodeURI(s)
}

export const fromUri = (s: string): string => {
	return decodeURI(s)
}
