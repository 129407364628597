import { Button, Text } from '@mantine/core'
import { IconEraser } from '@tabler/icons'

interface Props {
	onClick: () => void
}

export function ClearAction({ onClick }: Props) {
	return (
		<>
			<Button
				size="xs"
				leftIcon={<IconEraser size={19} />}
				onClick={() => onClick()}
			>
				<Text size={'sm'}>Clear</Text>
			</Button>
		</>
	)
}
