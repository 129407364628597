import { Tooltip } from '@mantine/core'
import { useClipboard } from '@mantine/hooks'

interface Props {
	content: string | undefined
	children: React.ReactNode
}

export function CopyContent({ content, children }: Props) {
	const clipboard = useClipboard()
	return (
		<Tooltip
			label="Copied to clipboard!"
			offset={5}
			position="top"
			radius="md"
			// transitionProperty="slide-up"
			// transitionDuration={100}
			opened={clipboard.copied}
		>
			<div onClick={() => clipboard.copy(content)}>{children}</div>
		</Tooltip>
	)
}
