import {
	createStyles,
	Container,
	Title,
	Text,
	Space,
	Paper,
	Center,
	Stack,
} from '@mantine/core'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import PasswordGeneratorForm, {
	PasswordOptions,
} from '../components/PasswordGenerationForm'
import { CopyContent } from '../components/actions/copy-node'

const useStyles = createStyles(theme => ({
	title: {
		fontFamily: `Helvetica Neue, ${theme.fontFamily}`,
		textAlign: 'center',
		fontWeight: 900,
		fontSize: 42,
		cursor: 'pointer',

		[theme.fn.smallerThan('md')]: {
			fontSize: 32,
		},
		[theme.fn.smallerThan('sm')]: {
			fontSize: 26,
		},
	},

	description: {
		maxWidth: 540,
		margin: 'auto',
		fontStyle: 'italic',
		marginBottom: theme.spacing.xl,
	},
}))

function generatePassword(options: PasswordOptions): string {
	let password: string

	const { length, useNumbers, useSymbols } = options

	const asciiLetters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
	const specialChars = useSymbols ? '!@#$%^&amp;*' : ''
	const numbers = useNumbers ? '0123456789' : ''

	const passwordChars = asciiLetters + specialChars + numbers
	password = Array.from(
		{ length },
		() => passwordChars[Math.floor(Math.random() * passwordChars.length)]
	).join('')

	return password
}

export function AdvancedPasswordGenerator() {
	const { classes } = useStyles()

	const [password, setPassword] = useState<string>(
		generatePassword({ length: 20, useNumbers: true, useSymbols: false })
	)

	return (
		<Stack spacing="xs" h={'100%'} justify="center">
			<Helmet>
				<title>Password Generator </title>
				<meta
					name="description"
					content="Generate passwords using special characters, numerals and literals"
				/>
				<script type="application/ld+json">
					{`
                        {
                            "@context": "http://schema.org",
                            "@type": "WebPage",
                            "name": "Advanced Password Generator",
                            "description": "Generate passwords using special characters, numerals and literals"
                        }
                    `}
				</script>
			</Helmet>

			<Container size={'md'}>
				<CopyContent content={password}>
					<Title size={'xs'} className={classes.title}>
						{password}
					</Title>
				</CopyContent>
				<Space h={'sm'} />

				<Text
					color="dimmed"
					size="sm"
					align="center"
					className={classes.description}
				>
					Click password to copy to clipboard
				</Text>
			</Container>

			<Container size={'xs'}>
				<PasswordGeneratorForm
					onSubmit={options => {
						setPassword(generatePassword(options))
					}}
				/>
			</Container>

			<Container size={'md'}>
				<Paper
					p="md"
					m="xl"
					shadow="xs"
					style={{ textAlign: 'center' }}
				>
					<Text color="dimmed" size="sm" align="center">
						Random passwords that incorporate a mix of alphanumeric
						characters and special symbols provide a high level of
						entropy, making them resistant to brute-force and
						dictionary-based attacks. The strength of these
						passwords is derived from their length and the variety
						of characters used, as it exponentially increases the
						number of possible combinations an attacker must try to
						successfully crack the password.
					</Text>
				</Paper>
			</Container>
		</Stack>
	)
}
