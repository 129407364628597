import React from 'react'
import { Container, createStyles, useMantineColorScheme } from '@mantine/core'

import Editor from '@monaco-editor/react'

const useStyles = createStyles(theme => ({
	editorWrapper: {
		padding: 0,
		margin: 0,
		borderLeft: `1px solid ${
			theme.colorScheme === 'dark'
				? theme.colors.dark[7]
				: theme.colors.gray[2]
		}`,
		borderRight: `1px solid ${
			theme.colorScheme === 'dark'
				? theme.colors.dark[7]
				: theme.colors.gray[2]
		}`,
	},
}))

interface Props {
	value: string | undefined
	language: string
	onEditContent?: (code: string) => void
}

const TextInputPage = ({ value, language, onEditContent }: Props) => {
	const { colorScheme } = useMantineColorScheme()
	const { classes } = useStyles()

	return (
		<Container fluid className={classes.editorWrapper}>
			<Editor
				height="87vh"
				width="100%"
				defaultLanguage="yaml"
				language={language}
				value={value}
				theme={colorScheme === 'light' ? 'vs-light' : 'vs-dark'}
				onChange={content => {
					if (onEditContent === undefined) return
					onEditContent(content ?? '')
				}}
				options={{
					readOnly: onEditContent === undefined,
					minimap: { enabled: false },
					wordWrap: 'on',
					automaticLayout: true,
					scrollbar: {
						vertical: 'hidden',
					},
					renderLineHighlight: 'gutter',
					// Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882

					lineDecorationsWidth: 2,
					lineNumbersMinChars: 2,
				}}
			/>
		</Container>
	)
}
export default TextInputPage
