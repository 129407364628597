import { useNavigate } from 'react-router-dom'

import { createStyles, Navbar, Text, Group, MediaQuery } from '@mantine/core'

interface MenuItem {
	emoji: string
	label: string
	link: string
	src?: string
	dst?: string
}

const utils = [
	{
		emoji: '🤌',
		label: 'UUID',
		link: '/uuid',
	},
	{
		emoji: '🌊',
		label: 'Random Emoji',
		link: '/emoji',
	},
]

const passwords = [
	{
		emoji: '🌋',
		label: 'Memorable Password Generator',
		link: '/password?mode=memorable',
	},
	{
		emoji: '🐌',
		label: 'Advanced Password Generator',
		link: '/password?mode=advanced',
	},
]

const transforming = [
	{
		emoji: '🫰',
		label: 'YAML to JSON',
		link: '/transform',
		src: 'yaml',
		dst: 'json',
	},
	{
		emoji: '🚚',
		label: 'JSON to YAML',
		link: '/transform',
		src: 'json',
		dst: 'yaml',
	},
]

const codeGeneration = [
	{
		emoji: '💻',
		label: 'Generate Go models',
		link: '/generate',
		src: 'json',
		dst: 'go',
	},
	// {
	// 	emoji: '🐍',
	// 	label: 'Generate Python (Pydantic) models',
	// 	link: '/generate',
	// 	src: 'json',
	// 	dst: 'python',
	// },
	{
		emoji: '📰',
		label: 'Generate TypeScript models',
		link: '/generate',
		src: 'json',
		dst: 'typescript',
	},
	// {
	// 	emoji: '📰',
	// 	label: 'JSON-schema to TypeScript models',
	// 	link: '/generate',
	// 	src: 'json-schema',
	// 	dst: 'typescript',
	// },
]

const encoding = [
	{
		emoji: '🍌',
		label: 'Text to Base64',
		link: '/transform',
		src: 'text',
		dst: 'base64',
	},
	{
		emoji: '🥸',
		label: 'Base64 to Text',
		link: '/transform',
		src: 'base64',
		dst: 'text',
	},
	{
		emoji: '🖼️',
		label: 'Image to Base64',
		link: '/transform',
		src: 'image',
		dst: 'base64',
	},
	{
		emoji: '🌐',
		label: 'Encode URI',
		link: '/transform',
		src: 'text',
		dst: 'uri',
	},
	{
		emoji: '🦁',
		label: 'Decode URI',
		link: '/transform',
		src: 'uri',
		dst: 'text',
	},
]

const checksumAlgoritms = [
	{
		emoji: '#️⃣',
		label: 'MD5',
		link: '/transform',
		src: 'text',
		dst: 'md5',
	},
	{
		emoji: '🐘',
		label: 'SHA1',
		link: '/transform',
		src: 'text',
		dst: 'sha1',
	},
	{
		emoji: '🙊',
		label: 'SHA256',
		link: '/transform',
		src: 'text',
		dst: 'sha256',
	},
	{
		emoji: '🔥',
		label: 'SHA512',
		link: '/transform',
		src: 'text',
		dst: 'sha512',
	},
]

const useStyles = createStyles(theme => ({
	navbar: {
		paddingTop: 0,
		overflow: 'scroll',
	},

	section: {
		marginLeft: -theme.spacing.md,
		marginRight: -theme.spacing.md,
		marginBottom: theme.spacing.md,

		'&:not(:last-of-type)': {
			borderBottom: `1px solid ${
				theme.colorScheme === 'dark'
					? theme.colors.dark[4]
					: theme.colors.gray[3]
			}`,
		},
	},

	searchCode: {
		fontWeight: 700,
		fontSize: 10,
		backgroundColor:
			theme.colorScheme === 'dark'
				? theme.colors.dark[7]
				: theme.colors.gray[0],
		border: `1px solid ${
			theme.colorScheme === 'dark'
				? theme.colors.dark[7]
				: theme.colors.gray[2]
		}`,
	},

	mainLinks: {
		paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
		paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
		paddingBottom: theme.spacing.md,
	},

	mainLink: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		fontSize: theme.fontSizes.xs,
		padding: `8px ${theme.spacing.xs}px`,
		borderRadius: theme.radius.sm,
		fontWeight: 700,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.dark[0]
				: theme.colors.gray[7],

		'&:hover': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[6]
					: theme.colors.gray[0],
			color: theme.colorScheme === 'dark' ? theme.white : theme.black,
		},
	},

	mainLinkInner: {
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},

	mainLinkIcon: {
		marginRight: theme.spacing.sm,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.dark[2]
				: theme.colors.gray[6],
	},

	mainLinkBadge: {
		padding: 0,
		width: 20,
		height: 20,
		pointerEvents: 'none',
	},

	collections: {
		paddingLeft: `calc(${theme.spacing.md} - 6)`,
		paddingRight: `calc(${theme.spacing.md} - 6)`,
		paddingBottom: theme.spacing.md,
	},

	collectionsHeader: {
		paddingLeft: theme.spacing.md + 2,
		paddingRight: theme.spacing.md,
		marginBottom: 5,
	},

	collectionLink: {
		display: 'block',
		padding: `8px ${theme.spacing.xs}`,
		textDecoration: 'none',
		borderRadius: theme.radius.sm,
		fontSize: theme.fontSizes.sm,
		fontWeight: 500,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.dark[0]
				: theme.colors.gray[7],
		lineHeight: 1,
		'&:hover': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[6]
					: theme.colors.gray[0],
			color: theme.colorScheme === 'dark' ? theme.white : theme.black,
		},
	},
}))

interface Props {
	opened: boolean
	setOpened: () => void
}

export function NavbarSearch({ opened, setOpened }: Props) {
	const navigate = useNavigate()
	const { classes } = useStyles()

	const setUpLinks = (items: MenuItem[]) => {
		return items.map(item => (
			<a
				href={item.link}
				onClick={event => {
					event.preventDefault()
					if (item.src) {
						const path = `${item.link}?src=${item.src}&dst=${item.dst}`
						navigate(path, {
							state: { src: item.src, dst: item.dst },
						})
					} else {
						navigate(`${item.link}`)
					}
					setOpened()
				}}
				key={item.label}
				className={classes.collectionLink}
			>
				<span style={{ marginRight: 9, fontSize: 16 }}>
					{item.emoji}
				</span>{' '}
				{item.label}
			</a>
		))
	}

	const generateSection = (title: string, items: MenuItem[]) => {
		return (
			<Navbar.Section className={classes.section}>
				<Group className={classes.collectionsHeader} position="apart">
					<Text size="sm" weight={500} color="dimmed">
						{title}
					</Text>
				</Group>
				<div className={classes.collections}>{setUpLinks(items)}</div>
			</Navbar.Section>
		)
	}
	const utilsSection = (
		<Navbar.Section className={classes.section}>
			<Group className={classes.collectionsHeader} position="apart">
				<Text size="sm" weight={500} color="dimmed">
					Utils
				</Text>
			</Group>
			<div className={classes.collections}>{setUpLinks(utils)}</div>
		</Navbar.Section>
	)
	const passwordsSection = (
		<Navbar.Section className={classes.section}>
			<Group className={classes.collectionsHeader} position="apart">
				<Text size="sm" weight={500} color="dimmed">
					Passwords
				</Text>
			</Group>
			<div className={classes.collections}>{setUpLinks(passwords)}</div>
		</Navbar.Section>
	)

	return (
		<Navbar
			width={{ sm: 300, lg: 300 }}
			p="md"
			hiddenBreakpoint="sm"
			hidden={!opened}
			className={classes.navbar}
		>
			{/* <TextInput
				placeholder="Search"
				size="sm"
				rightSectionWidth={70}
				styles={{ rightSection: { pointerEvents: 'none' } }}
				mb="sm"
			/> */}
			<>
				{generateSection('Transform', transforming)}
				{utilsSection}
				{passwordsSection}
				{generateSection('Code Generation', codeGeneration)}
				{generateSection('Encode / Decode', encoding)}
				{generateSection('Generate Check-sum', checksumAlgoritms)}
			</>
		</Navbar>
	)
}
