import { createStyles } from '@mantine/core'
import * as React from 'react'
import { Swap } from './actions/swap'

const useStyles = createStyles(theme => ({
	root: {
		paddingLeft: 0,
	},
	content: {
		display: 'flex',
		flexGrow: 1,
		paddingTop: '3rem',
		width: '3rem',
		height: '100%',
	},
}))

interface IConfigurationPaneProps {
	canSwapContent: boolean
	onSwapContent: () => void
}

const ConfigurationPane = ({
	canSwapContent,
	onSwapContent,
}: IConfigurationPaneProps) => {
	const { classes, cx } = useStyles()
	return (
		<div className={classes.content}>
			<Swap onSwap={canSwapContent ? onSwapContent : undefined} />
		</div>
	)
}

export default ConfigurationPane
