import { parse, stringify } from 'yaml'

export const YamlToJSON = (s: string): string | undefined => {
	try {
		return JSON.stringify(parse(s), null, 4)
	} catch (error) {
		return undefined
	}
}

export const JSONToYaml = (s: string): string | undefined => {
	try {
		return stringify(JSON.parse(s))
	} catch (error) {
		return undefined
	}
}
