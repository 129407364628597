import {
	createStyles,
	Container,
	Title,
	Text,
	Button,
	Group,
	Paper,
} from '@mantine/core'
import { LargeCopy } from '../components/actions/large-copy'
import { v4 } from 'uuid'
import { IconRotate } from '@tabler/icons'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { CopyContent } from '../components/actions/copy-node'

const useStyles = createStyles(theme => ({
	root: {
		paddingTop: 80,
		paddingBottom: 80,
	},

	inner: {
		position: 'relative',
	},

	image: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		zIndex: 0,
		opacity: 0.75,
	},

	content: {
		paddingTop: 220,
		position: 'relative',
		zIndex: 1,

		[theme.fn.smallerThan('sm')]: {
			paddingTop: 120,
		},
	},

	title: {
		fontFamily: `Helvetica Neue, ${theme.fontFamily}`,
		textAlign: 'center',
		fontWeight: 900,
		fontSize: 42,
		cursor: 'pointer',

		[theme.fn.smallerThan('sm')]: {
			fontSize: 32,
		},
	},

	description: {
		maxWidth: 540,
		margin: 'auto',
		marginTop: theme.spacing.xl,
		marginBottom: theme.spacing.xl,
	},
}))

export function UUIDPage() {
	const { classes } = useStyles()
	const [id, setId] = useState<string>(v4())

	return (
		<Container className={classes.root}>
			<Helmet>
				<title>UUID, please</title>
				<meta
					name="description"
					content="Generate a UUID (v4) here. A UUID(universally unique identifier) is a label that for practical purposes can be considered unique. The term globally unique identifier (GUID) is also used."
				/>
			</Helmet>
			<div className={classes.inner}>
				<div className={classes.content}>
					<CopyContent content={id}>
						<Title className={classes.title}>{id}</Title>
					</CopyContent>

					<Text
						color="dimmed"
						size="lg"
						align="center"
						className={classes.description}
					>
						Here, have a UUID!
					</Text>
					<Group position="center">
						<Button
							size={'xs'}
							leftIcon={<IconRotate size={19} stroke={1.5} />}
							onClick={() => setId(v4())}
						>
							Another!
						</Button>
						<LargeCopy content={id} />
					</Group>

					<Paper
						p="md"
						m="xl"
						shadow="xs"
						style={{ textAlign: 'center' }}
					>
						<Text color="dimmed" size="sm" align="center">
							UUIDs (Universally Unique Identifiers) are 128-bit
							numbers designed to ensure global uniqueness, which
							can help prevent conflicts and simplify data
							management in distributed systems. By using UUIDs,
							you can efficiently generate unique keys for
							databases, objects, or resources, minimizing the
							risk of collisions and simplifying synchronization
							across systems.
						</Text>
					</Paper>
				</div>
			</div>
		</Container>
	)
}
