import { Routes, Route } from 'react-router-dom'
import { NothingFoundBackground } from './pages/404'

import { Home } from './pages'
import { UUIDPage } from './pages/UUIDGenerationPage'
import { PasswordGenerator } from './pages/PasswordGenerationPage'
import { ConversionPage } from './pages/ConversionPage'
import { CookiesPolicyPage } from './pages/CookiesPolicyPage'
import RandomEmojiGenerator from './pages/EmojiGeneratorPage'

const Main = () => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/cookies" element={<CookiesPolicyPage />} />
			<Route path="/transform" element={<ConversionPage />} />
			<Route path="/generate" element={<ConversionPage />} />
			<Route path="/uuid" element={<UUIDPage />} />
			<Route path="/password" element={<PasswordGenerator />} />
			<Route path="/emoji" element={<RandomEmojiGenerator />} />
			<Route path="*" element={<NothingFoundBackground />} />
		</Routes>
	)
}
export default Main
