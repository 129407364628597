import React, { useState } from 'react'

import { AppShell } from '@mantine/core'
import { createStyles } from '@mantine/core'
import {
	MantineProvider,
	ColorSchemeProvider,
	ColorScheme,
} from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { NavbarSearch, SimpleHeader, SimpleFooter } from './mainpage'
import Main from './Main'
import { theme } from './theme'
import { Helmet } from 'react-helmet'
import CookiesBanner from './pages/CookiesBanner'

function App() {
	const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
		key: 'mantine-color-scheme',
		defaultValue: 'light',
		getInitialValueInEffect: true,
	})
	const toggleColorScheme = (value?: ColorScheme) =>
		setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

	// Prevent default browser action of dropping a file and showing it in plaintext
	window.addEventListener(
		'dragover',
		function (e) {
			e.preventDefault()
		},
		false
	)
	window.addEventListener(
		'drop',
		function (e) {
			e.preventDefault()
		},
		false
	)

	const [showNav, setShowNav] = useState<boolean>(false)
	return (
		<ColorSchemeProvider
			colorScheme={colorScheme}
			toggleColorScheme={toggleColorScheme}
		>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{ ...theme, colorScheme }}
			>
				<AppShell
					padding="xs"
					navbarOffsetBreakpoint="sm"
					navbar={
						<NavbarSearch
							opened={showNav}
							setOpened={() => setShowNav(!showNav)}
						/>
					}
					header={
						<SimpleHeader
							opened={showNav}
							setOpened={() => setShowNav(!showNav)}
						/>
					}
					styles={theme => ({
						...theme,
						main: {
							backgroundColor:
								theme.colorScheme === 'dark'
									? theme.colors.dark[8]
									: theme.colors.gray[0],
						},
					})}
					footer={<SimpleFooter />}
				>
					<Helmet>
						<title>Developer Tools</title>
					</Helmet>
					<Main />
					<CookiesBanner />
				</AppShell>
			</MantineProvider>
		</ColorSchemeProvider>
	)
}

export default App
