import * as React from 'react'
import { ECOLOGI_URL } from './constants'
import axios from 'axios'

const ECOLOGI_USERNAME = 'freedevtools'

export const CallGetTreeCount = async () => {
	const path = `${ECOLOGI_URL}/users/${ECOLOGI_USERNAME}/trees`
	const { data, status } = await axios.get(path, {
		headers: { 'content-type': 'application/json' },
	})
	return status === 200 ? data.total : 0
}
