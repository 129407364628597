import { Button, Tooltip, Text } from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { IconCopy, IconCheck } from '@tabler/icons'

interface Props {
	content: string | undefined
}

export function LargeCopy({ content }: Props) {
	const clipboard = useClipboard()
	return (
		<Tooltip
			label="Copied to clipboard!"
			offset={5}
			position="top"
			radius="md"
			// transition="slide-up"
			// transitionDuration={100}
			opened={clipboard.copied}
		>
			<Button
				variant="filled"
				leftIcon={
					clipboard.copied ? (
						<IconCheck size={19} stroke={1.5} />
					) : (
						<IconCopy size={19} stroke={1.5} />
					)
				}
				radius="sm"
				size="sm"
				styles={{
					root: { paddingRight: 14, height: 30 },
					rightIcon: { marginLeft: 22 },
				}}
				onClick={() => clipboard.copy(content)}
			>
				<Text size={'sm'}>Copy</Text>
			</Button>
		</Tooltip>
	)
}
