const BIJECTIVE_TRANSFORMATIONS: Record<string, boolean> = {
	'yaml-json': true,
	'json-yaml': true,
	'text-base64': true,
	'base64-text': true,
	'uri-text': true,
	'text-uri': true,
}

export const isBijective = (src: string, dst: string): boolean => {
	return `${src}-${dst}` in BIJECTIVE_TRANSFORMATIONS
}
