import React, { useState, useEffect } from 'react'
import { Container, Paper, Text, Button, Col, Grid } from '@mantine/core'

const CookiesBanner = () => {
	const [isBannerVisible, setIsBannerVisible] = useState(false)

	useEffect(() => {
		const cookiesAccepted = localStorage.getItem('cookiesAccepted')
		if (!cookiesAccepted) {
			setIsBannerVisible(true)
		}
	}, [])

	const handleAccept = () => {
		localStorage.setItem('cookiesAccepted', 'true')
		setIsBannerVisible(false)
	}

	if (!isBannerVisible) {
		return <></>
	}

	return (
		<Container>
			<Paper
				p="md"
				shadow="xs"
				style={{
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					zIndex: 1000,
					backgroundColor: '#f3f3f3',
				}}
			>
				<Grid>
					<Col span={9}>
						<Text>
							We use cookies to improve your experience on our
							website. By browsing this website, you agree to our
							use of cookies.
						</Text>
					</Col>
					<Col span={3} style={{ textAlign: 'right' }}>
						<Button color="primary" onClick={handleAccept}>
							Accept
						</Button>
					</Col>
				</Grid>
			</Paper>
		</Container>
	)
}

export default CookiesBanner
