import { useSearchParams } from 'react-router-dom'
import { MemorablePasswordGenerator } from './MemorablePasswordGenerationPage copy'
import { AdvancedPasswordGenerator } from './AdvancedPasswordGenerationPage'

export function PasswordGenerator() {
	const [searchParams] = useSearchParams()

	if (searchParams.get('mode') === 'memorable') {
		return <MemorablePasswordGenerator />
	} else {
		return <AdvancedPasswordGenerator />
	}
}
