import * as React from 'react'
import { API_URL } from './constants'
import axios from 'axios'

export enum FeedbackType {
	Bug = 'Bug',
	GeneralFeedback = 'General Feedback',
	FeatureRequest = 'Feature Request',
}

interface Feedback {
	message: string
	feedbackType: FeedbackType
}

interface ICallSendFeedbackProps {
	feedback: Feedback
}

export const CallSendFeedback = async ({
	feedback,
}: ICallSendFeedbackProps) => {
	const path = `${API_URL}/feedback`
	const { data, status } = await axios.post(path, feedback)
	return
}
