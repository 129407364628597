import { Group } from '@mantine/core'
import { BaseActionHeader } from './action-header'
import { WriteToFileAction } from './actions/write-to-file'
import { LargeCopy } from './actions/large-copy'
import short from 'short-uuid'

interface Props {
	content: string | undefined
	format: string
}

export function DestinationPaneActions({ content, format }: Props) {
	const writeToFile = (format: string, content: string) => {
		const EXTENSION_BY_FMT: Record<string, string> = {
			yaml: 'yaml',
			json: 'json',
			toml: 'toml',
			text: 'txt',
			html: 'html',
		}

		const trimmedContent = content.split(',')[0].includes('image/')
			? content.split(',')[1]
			: content

		const extension =
			format in EXTENSION_BY_FMT
				? `.${EXTENSION_BY_FMT[format]}`
				: `.${format}`
		const filename = `${format}-${short().generate()}${extension}`

		const blob = new Blob([trimmedContent])
		const url = URL.createObjectURL(blob)

		const link = document.createElement('a')
		link.download = filename
		link.href = url
		link.click()
	}

	return (
		<BaseActionHeader
			title={format}
			prefix={'To'}
			center={<></>}
			right={
				<Group spacing={'xs'}>
					<LargeCopy content={content} />
					<WriteToFileAction
						onTrigger={() => writeToFile(format, content ?? '')}
					/>
				</Group>
			}
		/>
	)
}
