import React from 'react'
import { Container, Text } from '@mantine/core'
import { FeaturesImages } from '../components/features'
import {
	IconHash,
	Icon3dCubeSphere,
	IconLock,
	IconBrandPython,
} from '@tabler/icons'

const Home = () => {
	return (
		<Container>
			<FeaturesImages
				supTitle={'All your one-off developer needs'}
				description={`We serve disparate tools for your everyday developer needs. 
					Regardless if you are building a website in React, backend in Golang, training a model in tensorflow, 
					- sometimes you just need a quick util without cluttering your local workstation.`}
				data={[
					{
						icon: IconBrandPython,
						title: <Text>Code Generation</Text>,
						description: (
							<Text>
								Automatically generate handsome models for our
								favorite languages, just by pasting or uploading
								your a JSON blob.
							</Text>
						),
					},
					{
						icon: Icon3dCubeSphere,
						title: <Text>Transformations </Text>,
						description: (
							<Text>
								Map your data between common web formats such as
								JSON, Yaml, Toml with ease.
							</Text>
						),
					},
					{
						icon: IconHash,
						title: <Text>Generate Check-sum</Text>,
						description: (
							<Text>
								Hash your data using the most common and widely
								used check sum algoritms.
							</Text>
						),
					},
					{
						icon: IconLock,
						title: <Text>Quick Utils</Text>,
						description: (
							<Text>
								Password generators, UUIDs, code transformations
								- all generated on the fly ready to be used.
							</Text>
						),
					},
				]}
			/>
		</Container>
	)
}
export default Home
