import React, { useState } from 'react'
import {
	Button,
	Group,
	LoadingOverlay,
	Modal,
	NativeSelect,
	Space,
	Textarea,
	Text,
	createStyles,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { CallSendFeedback, FeedbackType } from '../api/call-send-feedback'

const useStyles = createStyles(theme => ({
	link: {
		cursor: 'pointer',
		fontWeight: 600,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.gray[5]
				: theme.colors.gray[7],
	},
}))

export const FeedbackModal = () => {
	const { classes } = useStyles()

	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [isSending, setIsSending] = useState<boolean>(false)

	const form = useForm({
		initialValues: {
			message: '',
			feedbackType: FeedbackType.GeneralFeedback,
		},
	})

	const sendFeedback = async (
		message: string,
		feedbackType: FeedbackType
	) => {
		setIsSending(true)

		try {
			await CallSendFeedback({ feedback: { message, feedbackType } })
			setIsOpen(false)
			form.reset()
		} catch (error) {
			console.error(error)
		} finally {
			setIsOpen(false)
		}
	}

	const callSendFeedback = (message: string, feedbackType: FeedbackType) => {
		sendFeedback(message, feedbackType)
	}

	return (
		<>
			{!isOpen ? (
				<Text
					className={classes.link}
					variant="text"
					size="sm"
					weight={600}
					onClick={() => {
						setIsOpen(true)
					}}
				>
					Feedback
				</Text>
			) : (
				<Modal
					centered
					overlayProps={{ opacity: 0.55, blur: 3 }}
					opened={isOpen}
					title="Leave Feedback"
					onClose={() => {
						setIsOpen(false)
					}}
				>
					<>
						<Text size={'sm'} color={'dimmed'}>
							Thank you for taking the time to provide feedback.
							Please let us know if you need a particulary tool,
							or have discovered a bug.
						</Text>
						<Space h={'xl'} />
						<form
							onSubmit={form.onSubmit(values =>
								callSendFeedback(
									values.message,
									values.feedbackType
								)
							)}
						>
							<LoadingOverlay
								visible={isSending}
								overlayBlur={2}
							/>
							<NativeSelect
								data={[
									'General Feedback',
									'Feature Request',
									'Bug',
								]}
								placeholder="Please specify"
								label="Specify type of feedback"
								{...form.getInputProps('feedbackType')}
							/>
							<Space h={'md'} />
							<Textarea
								placeholder="Your comment"
								label="Your comment"
								withAsterisk
								{...form.getInputProps('message')}
							/>
							<Space h={'lg'} />
							<Group position="right" mt="md">
								<Button
									disabled={form.values.message === ''}
									type="submit"
								>
									Submit
								</Button>
							</Group>
						</form>
					</>
				</Modal>
			)}
		</>
	)
}
