import {
	Box,
	Center,
	Container,
	createStyles,
	Group,
	SegmentedControl,
	Space,
} from '@mantine/core'
import { IconCursorText, IconFile } from '@tabler/icons'
import { ClearAction } from './actions/clear'
import { LargeCopy } from './actions/large-copy'
import { BaseActionHeader } from './action-header'

const useStyles = createStyles(theme => ({
	itemsWrapper: {
		padding: 0,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}))

export enum InputType {
	Text = 'text',
	File = 'file',
}

interface Props {
	format?: string
	content: string | undefined
	onClear: () => void

	inputType: InputType
	onSetInputType?: () => void
}

export function SourcePaneActions({
	format,
	content,
	inputType,
	onClear,
	onSetInputType,
}: Props) {
	const { classes, cx } = useStyles()
	return (
		<BaseActionHeader
			title={format}
			prefix={'From'}
			center={
				<SegmentedControl
					size="sm"
					value={inputType.toString()}
					disabled={onSetInputType === undefined}
					onChange={onSetInputType}
					data={[
						{
							value: InputType.Text.toString(),
							label: (
								<Center>
									<IconCursorText size={16} stroke={1.5} />
									<Box ml={10}>Text</Box>
								</Center>
							),
						},
						{
							value: InputType.File.toString(),
							label: (
								<Center>
									<IconFile size={16} stroke={1.5} />
									<Box ml={10}>File</Box>
								</Center>
							),
						},
					]}
				/>
			}
			right={
				<Group spacing={'xs'}>
					<LargeCopy content={content} />
					<ClearAction onClick={onClear} />
				</Group>
			}
		/>
	)
}
