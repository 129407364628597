import { Button, Text } from '@mantine/core'
import { IconFileDownload } from '@tabler/icons'

interface Props {
	onTrigger: () => void
}

export function WriteToFileAction({ onTrigger }: Props) {
	return (
		<Button
			size="xs"
			leftIcon={<IconFileDownload size={17} />}
			onClick={onTrigger}
		>
			<Text size={'sm'}>Write To File</Text>
		</Button>
	)
}
